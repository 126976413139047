import React, { useContext } from 'react';
import { UserContext } from '../../common/hooks/useUser';
import { mountAddress } from '../../pages/app/positions/EditAddress.jsx';

function AccountPanel() {
  const { user, firebaseData = {} } = useContext(UserContext);
  const { data } = firebaseData;

  return (
    <section className="space-y-8">
      <div>
        <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Dados pessoais</h2>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="name">
              Nome
            </label>
            <input id="name" readOnly className="form-input w-full" type="text" value={data?.displayName || ''} />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="phone">
              Telefone
            </label>
            <input id="phone" readOnly className="form-input w-full" type="text" value={user?.phone || ''} />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="cpf">
              CPF
            </label>
            <input id="cpf" readOnly className="form-input w-full" type="text" />
          </div>
        </div>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="w-full">
            <label className="block text-sm font-medium mb-1" htmlFor="location">
              Endereço
            </label>
            <input
              id="location"
              readOnly
              value={mountAddress(user?.Address || {})}
              className="form-input w-full"
              type="text"
            />
          </div>
        </div>
      </div>
      <div>
        <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Perfil profissional</h2>
        <div className="text-sm">Mantenha seu perfil atualizado para que as empresas possam encontrar você.</div>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="experience_type">
              Experiência
            </label>
            <input
              id="experience_type"
              readOnly
              value={user?.experience_type || ''}
              className="form-input w-full"
              type="text"
            />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="experience_sector">
              Setor
            </label>
            <input
              id="experience_sector"
              readOnly
              value={user?.experience_sector || ''}
              className="form-input w-full"
              type="text"
            />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="experience_duration">
              Tempo de experiência
            </label>
            <input
              id="experience_duration"
              readOnly
              value={user?.experience_duration || ''}
              className="form-input w-full"
              type="text"
            />
          </div>
        </div>
        <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="education_level">
              Escolaridade
            </label>
            <input
              id="education_level"
              readOnly
              value={user?.education_level || ''}
              className="form-input w-full"
              type="text"
            />
          </div>
          <div className="sm:w-1/3">
            <label className="block text-sm font-medium mb-1" htmlFor="work_preference">
              Trabalho de preferência
            </label>
            <input
              id="work_preference"
              readOnly
              value={user?.work_preference || ''}
              className="form-input w-full"
              type="text"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default AccountPanel;
