import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FullLogo from '../../images/full_logo_colored.svg';

import Button from '../../components/Button';
import AuthImage from '../../images/auth-image.jpg';
import AuthDecoration from '../../images/auth-decoration.png';

function AccessCode() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);

  function getOtp({ currentTarget }) {
    return Array.from(currentTarget.elements)
      .map(({ value }) => value)
      .join('');
  }

  function handleFormSubmit(e) {
    sessionStorage.removeItem('entity');
    setLoading(true);
    e.preventDefault();
    const otp = getOtp(e);
    if (otp.length !== 6) return;
    window.confirmationResult
      .confirm(otp)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        setLoading(false);
        Sentry.captureException(error);
        toast.error('Código inválido, tente novamente.');
      });
  }

  function onChange({ target }) {
    if (!/^\d+$/.test(target?.value)) return (target.value = '');
    const { nextElementSibling } = target;
    nextElementSibling ? nextElementSibling.focus() : target.parentElement.nextElementSibling.lastChild.click();
  }

  function onFocus({ target }) {
    target.value = '';
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="w-auto h-8" src={FullLogo} alt="Trabalha AI Logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Insira o código</h1>
              <p className="text-sm text-slate-600 dark:text-slate-400 mb-6">
                Insira o código de 6 dígitos que enviamos por SMS para o número{' '}
                <b>{`${state.country.value} ${state.number}`}</b>.
              </p>

              {/* Form */}
              <form onSubmit={handleFormSubmit}>
                <div className="flex items-center justify-between">
                  {[...Array(6)].map((e, i) => (
                    <input
                      key={i}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={i === 0}
                      type="text"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength={1}
                      onFocus={onFocus}
                      onChange={onChange}
                      className="form-input border-1 text-center text-2xl font-bold focus:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 w-11 h-11 sm:w-12 sm:h-12"
                    />
                  ))}
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      to="/auth/login/phone"
                      className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                    >
                      Voltar
                    </Link>
                  </div>
                  <Button loading={loading} className="text-white ml-3 whitespace-nowrap min-w-[5rem]">
                    Confirmar
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default AccessCode;
