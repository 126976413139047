import React from 'react';
import { formatDateToShortDate } from '../../../utils/Utils';

import { openPositionStatus, JOB_LIST_ENUM } from '../../../common/utils';

export function formatField(field) {
  return field?.split(' ')[0];
}

export function formatWorkSchedule(workSchedule = '') {
  const SCHEDULE = {
    4: 'Meio período',
    8: 'Período integral',
  };
  return SCHEDULE[workSchedule.split('/')[0].split(' ')[0]];
}

function JobListItem(props) {
  function formatDetails(props) {
    return [props.city, formatWorkSchedule(props.work_schedule), props.salary_range].join(' / ');
  }

  const { label, color } = JOB_LIST_ENUM[props.type] || {};

  return (
    <div>
      <div>
        <div
          className={`shadow-lg rounded-sm border px-5 py-4 ${
            openPositionStatus.includes(props.type)
              ? 'bg-amber-50 dark:bg-amber-400/10 border-amber-300 dark:border-amber-400/50'
              : 'bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700'
          }`}
        >
          <div className="md:flex justify-between items-center space-y-4 md:space-y-0 space-x-2">
            {/* Left side */}
            <div className="flex items-start space-x-3 md:space-x-4">
              {props.image && (
                <div className="w-9 h-9 shrink-0 mt-1 hidden">
                  <img className="w-9 h-9 rounded-full" src={props.image} width="36" height="36" alt={props.company} />
                </div>
              )}
              <div>
                <div className="inline-flex font-semibold text-slate-800 dark:text-slate-100">{props.role}</div>
                <div className="text-sm">{formatDetails(props)}</div>
              </div>
            </div>
            {/* Right side */}
            <div className="flex items-center space-x-4 pl-10 md:pl-0">
              {props.activeCandidatures > 0 && !['RELEASED_CANDIDATES', 'CLOSED', 'CANCELLED'].includes(props.type) && (
                <div className="text-xs text-slate-500 max-w-48">
                  Já temos{' '}
                  {props.activeCandidatures > 1
                    ? `${props.activeCandidatures} pessoas validadas e interessadas`
                    : '1 pessoa validada e interessada'}
                </div>
              )}
              <div className="flex flex-col space-y-1">
                <div className="text-sm text-slate-500 dark:text-slate-400 italic whitespace-nowrap">
                  {formatDateToShortDate(props.date)}
                </div>
              </div>
              {props.type && (
                <div
                  className={`text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 w-45 justify-center ${
                    color || 'bg-amber-100 dark:bg-amber-400/30 text-amber-600 dark:text-amber-400'
                  }`}
                >
                  {label}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobListItem;
