import React, { useState, useRef, useEffect, useContext } from 'react';
import { useMutation } from 'react-query';
import { UserContext } from '../../common/hooks/useUser';
import Transition from '../../utils/Transition';

import MessageIcon from './MessageIcon';
import Notifications from './Notifications';

import ContentLoader from 'react-content-loader';
import { readNotifications } from '../../common/services/app';

function DropdownNotifications({ align }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const onClose = () => setDropdownOpen(!dropdownOpen);

  const { mutate, isSuccess } = useMutation(readNotifications);

  const myContent = useContext(UserContext);
  const { user = {}, isUserFetched } = myContent;
  const { notifications } = user;
  const hasUnreadNotifications = notifications?.some((notification) => notification.status === 'unreaded');

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // read messages
  useEffect(() => {
    if (hasUnreadNotifications && dropdownOpen && !isSuccess) {
      mutate();
    }
  }, [dropdownOpen, isSuccess, mutate, hasUnreadNotifications]);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${
          dropdownOpen && 'bg-slate-200'
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Notifications</span>
        <MessageIcon className="w-4 h-4" />
        {hasUnreadNotifications && (
          <div className="absolute top-0 right-0 w-3 h-3 bg-rose-500 border-2 border-white dark:border-[#182235] rounded-full"></div>
        )}
      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full -mr-40 sm:mr-0 min-w-56 sm:min-w-80 bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
          align === 'right' ? 'right-0' : 'left-0'
        }`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-4">
            Notificações
          </div>
          {!isUserFetched && (
            <div className="space-y-4 px-2">
              <ContentLoader
                speed={3}
                width={300}
                height={100}
                viewBox="0 0 285 100"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="15" rx="5" ry="5" width="274" height="16" />
                <rect x="0" y="35" rx="5" ry="5" width="274" height="30" />
                <rect x="0" y="75" rx="5" ry="5" width="155" height="16" />
              </ContentLoader>
            </div>
          )}
          {isUserFetched && (
            <>
              {notifications?.length > 0 ? (
                <Notifications notifications={notifications} onClose={onClose} />
              ) : (
                <div className="text-center p-3 sm:p-6">
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gray-100 mb-4">
                    <MessageIcon className="w-8 h-8" />
                  </div>
                  <p className="text-sm text-slate-500 dark:text-slate-400">Você ainda não tem notificações.</p>
                  <p className="text-xs text-gray-400 mt-1">Te avisaremos quando tiver algo novo!</p>
                </div>
              )}
            </>
          )}
        </div>
      </Transition>
    </div>
  );
}

export default DropdownNotifications;
