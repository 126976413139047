export function formatPhone(value: string): string {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
}

export function formatCpf(v) {
  v = v.replace(/\D/g, "")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d)/, "$1.$2")
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  return v
}


export function formatCnpj(v) {
  return v
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export function formatDate(value: string): string {
  return new Date(value).toLocaleDateString('pt-BR');
}

export function formatDateLong(value: string): string {
  return new Date(value).toLocaleDateString('pt-BR', { day: 'numeric', month: 'long', year: 'numeric' });
}

export function formatPrice(value: number): string {
  return (value / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function timeDifference(endDateString: string) {
  const endDate = new Date(endDateString);
  return (endDate.getTime() - new Date().getTime()) / 1000;
}

export function checkDateAfterNow(dateString: string) {
  return new Date(dateString) > new Date();
}

export function getEnv(): string {
  return window.location.hostname.includes('localhost') ? 'development' : 'production';
}

export function isDevMode() {
  const env = getEnv();
  return env === 'development';
}

export const openPositionStatus = ['PENDING', 'RELEASED'];

export const POSITION_LIST_ENUM = {
  WAITING_CANDIDATES: {
    label: 'Aguardando candidatos',
    color: 'bg-slate-100 dark:bg-slate-400/30 text-slate-600 dark:text-slate-400',
  },
  CLOSED: {
    label: 'Encerrada',
    color: 'bg-blue-100 dark:bg-blue-500/30 text-blue-600 dark:text-blue-500',
  },
  CANCELLED: {
    label: 'Cancelada',
    color: 'bg-red-100 dark:bg-red-500/30 text-red-600 dark:text-red-500',
  },
  RECRUITMENT: {
    label: 'Recrutamento',
    color: 'bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400',
  },
  CANDIDATES_REVIEW: {
    label: 'Candidatos encontrados',
    color: 'bg-sky-100 dark:bg-sky-400/30 text-sky-600 dark:text-sky-400',
  },
  RELEASED_CANDIDATES: {
    label: 'Candidatos liberados',
    color: 'bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400',
  },
};

export const JOB_LIST_ENUM = {
  PENDING: {
    label: 'Aguardando empresa',
    color: 'bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400',
  },
  REJECTED: {
    label: 'Você recusou',
    color: 'bg-red-100 dark:bg-red-500/30 text-red-600 dark:text-red-500',
  },
  CLOSED: {
    label: 'Encerrada',
    color: 'bg-slate-100 dark:bg-slate-400/30 text-slate-600 dark:text-slate-400',
  },
  CANCELLED: {
    label: 'Encerrada',
    color: 'bg-slate-100 dark:bg-slate-400/30 text-slate-600 dark:text-slate-400',
  },
  REJECTED_BY_COMPANY: {
    label: 'Recusado pela empresa',
    color: 'bg-slate-100 dark:bg-slate-400/30 text-slate-600 dark:text-slate-400',
  },
  RELEASED: {
    label: 'Aguardando empresa',
    color: 'bg-indigo-100 dark:bg-indigo-500/30 text-indigo-600 dark:text-indigo-400',
  },
  // RELEASED_CANDIDATES: {
  //   label: 'Candidatos liberados',
  //   color: 'bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400',
  // },
};