import React, { useContext } from 'react';
import { UserContext } from '../../common/hooks/useUser';

import { mountAddress } from '../../pages/app/positions/EditAddress.jsx';

function AccountPanel() {
  const { user, firebaseData = {} } = useContext(UserContext);
  const { data } = firebaseData;

  return (
    <section>
      <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Perfil da empresa</h2>
      <div className="text-sm">
        Mantenha o perfil da sua empresa atualizado para que mais candidatos possam encontrar você.
      </div>
      <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="name">
            Nome
          </label>
          <input id="name" readOnly value={data?.displayName} className="form-input w-full" type="text" />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="business-id">
            Nome da empresa
          </label>
          <input id="business-id" readOnly value={user?.companyName} className="form-input w-full" type="text" />
        </div>
        <div className="sm:w-1/3">
          <label className="block text-sm font-medium mb-1" htmlFor="phone">
            Celular
          </label>
          <input id="phone" readOnly value={user?.phone} className="form-input w-full" type="text" />
        </div>
      </div>
      <div className="sm:flex sm:items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-5">
        <div className="w-full">
          <label className="block text-sm font-medium mb-1" htmlFor="location">
            Endereço
          </label>
          <input
            id="location"
            readOnly
            value={mountAddress(user?.Address || {})}
            className="form-input w-full"
            type="text"
          />
        </div>
        <div className="sm:w-1/2">
          <label className="block text-sm font-medium mb-1" htmlFor="cnpj">
            CNPJ
          </label>
          <input id="cnpj" readOnly value={user?.cnpj} className="form-input w-full" type="text" />
        </div>
      </div>
    </section>
  );
}

export default AccountPanel;
