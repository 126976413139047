import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import FullLogo from '../../images/full_logo_colored.svg';

import PhoneInput from '../../components/PhoneInput';
import AuthImage from '../../images/auth-image.jpg';
import AuthDecoration from '../../images/auth-decoration.png';

import { signWithPhone, generateRecaptcha } from '../../common/services/auth';

function Signup() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const isFilled = phone?.number.length === 15;

  function getNumber() {
    const { number, country } = phone;
    if (number && country.value) {
      return country.value + number.replace(/\D/g, '');
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    setLoading(true);
    generateRecaptcha('send-code');
    signWithPhone(getNumber())
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        navigate('access-code', { state: phone });
      })
      .catch(() => {
        setLoading(false);
        toast.error('Ocorreu um erro ao enviar o código de acesso, tente novamente mais tarde.');
      });
  }

  return (
    <main className="bg-white dark:bg-slate-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="w-auto h-8" src={FullLogo} alt="Trabalha AI Logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6">Informe seu número</h1>
              <p className="text-sm text-slate-600 dark:text-slate-400 mb-6">
                Para sua conta ficar mais segura, vamos enviar um código de confirmação por SMS para seu telefone.{' '}
                <b>Você só precisa fazer isso uma vez.</b>
              </p>

              {/* Form */}
              <form onSubmit={handleFormSubmit}>
                <PhoneInput value={phone} onChange={setPhone} />
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <Link
                      to="/auth/login"
                      className="btn dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-indigo-500"
                    >
                      Voltar
                    </Link>
                  </div>
                  <Button
                    id="send-code"
                    loading={loading}
                    disabled={!isFilled}
                    className="text-white ml-3 whitespace-nowrap min-w-[5rem]"
                  >
                    Enviar código
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
          <img
            className="absolute top-1/4 left-0 -translate-x-1/2 ml-8 hidden lg:block"
            src={AuthDecoration}
            width="218"
            height="224"
            alt="Authentication decoration"
          />
        </div>
      </div>
    </main>
  );
}

export default Signup;
