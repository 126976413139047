import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateLong } from '../../common/utils';

export default function Notifications({ notifications, onClose }) {
  return (
    <ul>
      {notifications.map(({ id, title, message, cta, createdAt }) => (
        <li key={id} className="border-b border-slate-200 dark:border-slate-700 last:border-0">
          <Link
            className="block py-2 px-4 hover:bg-slate-50 dark:hover:bg-slate-700/20"
            to={cta || '#0'}
            onClick={onClose}
          >
            <span className="block text-sm mb-2">
              <span className="font-medium text-slate-800 dark:text-slate-100">{title}</span> {message}
            </span>
            <span className="block text-xs font-medium text-slate-400 dark:text-slate-500">
              {formatDateLong(createdAt)}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
}
